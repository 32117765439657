import { render, staticRenderFns } from "./studentsByNeighborhood.vue?vue&type=template&id=0dab4844&scoped=true&"
import script from "./studentsByNeighborhood.js?vue&type=script&lang=js&"
export * from "./studentsByNeighborhood.js?vue&type=script&lang=js&"
import style0 from "./studentsByNeighborhood.scss?vue&type=style&index=0&id=0dab4844&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dab4844",
  null
  
)

export default component.exports